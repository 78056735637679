.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.card {
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.logo {
  height: 50px;
}

.title {
  margin-bottom: 20px;
}

.sub-title {
  margin-top: 10px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 30px;
}

.form-group label {
  display: block;
  margin-bottom: 15px;
  text-align: left;
  margin-left: 25px;
}

.submit-button {
  margin: 30px 0px;

}

.input-field{
  width: 90%;
}

.form-group .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  height: 0.7em;
}

.content .css-bhp9pd-MuiPaper-root-MuiCard-root{
  margin: 50px 10px;
}

.content .title{
  color: #c91728;
  margin-bottom: 20px;
  font-weight: 600;
}

.form-group .css-1ujsas3, .form-group .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  color: #000!important;
}


/* Media Query for Tablet */
@media only screen and (max-width: 768px) {
  .card {
    width: 80%; /* Adjusted width for tablets */
  }

  .content .css-bhp9pd-MuiPaper-root-MuiCard-root{
    margin: 50px 20px;
  }
}

/* Media Query for Mobile */
@media only screen and (max-width: 480px) {
  .card {
    width: 90%; /* Adjusted width for mobile */
  }
}